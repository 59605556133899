module.exports = [{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Righteous","Roboto"]}},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-152626536-1"},
    },{
      plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BI-Esport.dk","short_name":"BIEsport","start_url":"/","icon":"src/images/favicon.svg","background_color":"#000a21","theme_color":"#FFF","display":"standalone"},
    }]
