// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/render/project/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/render/project/src/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("/opt/render/project/src/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-lan-js": () => import("/opt/render/project/src/src/pages/lan.js" /* webpackChunkName: "component---src-pages-lan-js" */),
  "component---src-pages-spil-js": () => import("/opt/render/project/src/src/pages/spil.js" /* webpackChunkName: "component---src-pages-spil-js" */),
  "component---src-pages-sponsor-js": () => import("/opt/render/project/src/src/pages/sponsor.js" /* webpackChunkName: "component---src-pages-sponsor-js" */),
  "component---src-pages-tilmelding-js": () => import("/opt/render/project/src/src/pages/tilmelding.js" /* webpackChunkName: "component---src-pages-tilmelding-js" */)
}

